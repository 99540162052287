<template>
  <expansion-sheet :elevation="locationOverviewElevation" color="foreground" rounded>
    <template v-slot:header>
      <mex-p content="Locations" fontSize="h6" fontWeight="bold-italic" />
    </template>
    <template v-slot:content>
      <v-row justify="center">
        <template v-if="!hideClinicGoTo || !hideOrgaGoTo">
          <v-col cols="2">
            <v-row v-if="!hideClinicGoTo" justify="start">
              <v-col cols="auto">
                <v-tooltip left>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small v-bind="attrs" v-on="on" @click="goToClinicView">
                      <v-icon small class="mr-1">mdi-arrow-right-bold-hexagon-outline</v-icon>
                      Clinic
                    </v-btn>
                  </template>
                  <span>Go to clinic view</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="!hideOrgaGoTo" justify="start">
              <v-col cols="auto">
                <v-tooltip left>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small v-bind="attrs" v-on="on" @click="goToOrganizationView">
                      <v-icon small class="mr-1">mdi-arrow-right-bold-hexagon-outline</v-icon>
                      Organization
                    </v-btn>
                  </template>
                  <span>Go to organization view</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <!-- if wideTable is true: columns are 12, else: 10 -->
        <v-col :cols="10 + 2 * Number(wideTable)">
          <mex-data-table
            :data="locations"
            :headers="getTableHeaders"
            tableClass="foreground"
            dense
            :footer-props="{
              'items-per-page-options': getTablePagination.rowsPerPage,
            }"
            hide-default-footer
            :items-per-page="getTablePagination.defaultRowsPerPage"
            class="ma-0"
          >
            <template v-if="readLocations" v-slot:item.goToLocation="{ item }">
              <v-btn color="foreground" small @click="goToLocationView(item.LocationID)">{{ item.name }}</v-btn>
            </template>
            <template v-else v-slot:item.goToLocation="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.installationDate="{ item }">
              {{ item.installationDate ? item.installationDate.replace('Z', '').replace('T', ' ') : '-' }}
            </template>
            <template v-slot:item.rentalSettings="{}">
              <v-btn x-small @click="goToRentalSettings()">
                <v-icon small>mdi-playlist-plus</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.featureView="{ item }">
              <v-btn x-small @click="goToFeatureView(item.LocationID)">
                <v-icon small>mdi-puzzle</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.featureSettings="{ item }">
              <v-btn x-small @click="goToFeatureSettings(item.LocationID)">
                <v-icon small>mdi-puzzle-check</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.locationView="{ item }">
              <mex-btn small icon="mdi-hospital-marker" iconOnly @click="goToLocationView(item.LocationID)" />
            </template>
          </mex-data-table>
        </v-col>
      </v-row>
    </template>
  </expansion-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import ExpansionSheet from './ExpansionSheet.vue';
import tablePagination from '../../config/tablePagination.config';

export default {
  components: { ExpansionSheet },
  name: 'LocationOverview',
  props: {
    clinicID: {
      type: String | Number,
      default: null,
    },
    organizationID: {
      type: Number,
      default: null,
    },
    locations: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideClinicGoTo: {
      type: Boolean,
      default: true,
    },
    hideOrgaGoTo: {
      type: Boolean,
      default: true,
    },
    locationOverviewElevation: {
      type: String,
      default: '6',
    },
    wideTable: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getTableHeaders() {
      let newTableHeaders = this.locationTableHeaders;
      if (!this.readRentalLicense) {
        newTableHeaders = newTableHeaders.filter((x) => {
          return x.value !== 'rentalSettings';
        });
      }
      if (!this.readFeatureLicense) {
        newTableHeaders = newTableHeaders.filter((x) => {
          return x.value !== 'featureView';
        });
      }
      if (!this.featureSettings) {
        newTableHeaders = newTableHeaders.filter((x) => {
          return x.value !== 'featureSettings';
        });
      }
      return newTableHeaders;
    },
    readLocations() {
      return this.getUserPermissions.includes('read_Customer Administration_Location Administration');
    },
    readRentalLicense() {
      return this.getUserPermissions.includes('read_License Administration_Rental License');
    },
    featureSettings() {
      return this.getUserPermissions.includes('_License Administration_License Settings');
    },
    readFeatureLicense() {
      return this.getUserPermissions.includes('read_License Administration_Feature License');
    },
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      locationTableHeaders: [
        {
          text: 'Location name',
          align: 'start',
          sortable: false,
          value: 'goToLocation',
        },
        {
          text: 'Located in',
          align: 'center',
          sortable: false,
          value: 'Addresses[0].city', // It's always only one address here
        },
        {
          text: 'Installation date',
          align: 'center',
          sortable: false,
          value: 'installationDate',
        },
        {
          text: 'Rental Settings',
          align: 'center',
          sortable: false,
          value: 'rentalSettings',
        },
        {
          text: 'Feature View',
          align: 'center',
          sortable: false,
          value: 'featureView',
        },
        {
          text: 'Feature Settings',
          align: 'center',
          sortable: false,
          value: 'featureSettings',
        },
        // {
        //   text: 'Location view',
        //   align: 'center',
        //   sortable: false,
        //   value: 'locationView',
        // },
      ],
    };
  },
  methods: {
    goToOrganizationView() {
      this.$router.push({ name: 'OrganizationView', params: { id: this.organizationID } });
    },
    goToClinicView() {
      this.$router.push({ name: 'ClinicView', params: { id: this.clinicID } });
    },
    goToLocationView(locationID) {
      this.$router.push({ name: 'LocationView', params: { id: locationID } });
    },
    goToRentalSettings() {
      this.$router.push({
        name: 'RentalLicenseSettings',
        params: { organizationID: this.organizationID, clinicID: this.clinicID },
      });
    },
    goToFeatureSettings(locationID) {
      this.$router.push({
        name: 'FeatureLicenseSettings',
        params: { organizationID: this.organizationID, clinicID: this.clinicID, locationID },
      });
    },
    goToFeatureView(locationID) {
      this.$router.push({
        name: 'FeatureLicenseView',
        params: { organizationID: this.organizationID, clinicID: this.clinicID, locationID },
      });
    },
  },
};
</script>

<style></style>
